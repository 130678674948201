<template>
  <Header title="Services" />

  <!-- Section -->
  <Container class="bg-gray-50">
    <div class="grid md:grid-cols-3 gap-standard">
      <ImageCardService
        v-for="service in services"
        :key="service.title"
        :href="service.href"
		:imageUrl="service.imageUrl"
		:summary="service.summary"
        >{{ service.title }}</ImageCardService>
    </div>
  </Container>
</template>

<script>
import ImageCardService from "../components/ImageCardService.vue";
import Container from "../components/Container.vue";
import Header from "../components/Header.vue";

const services = [
  { imageUrl: "/img/ROI.svg", title: "Owner/Shareholder R.O.I.", href: "/services/investment", summary: "Bryan and Hedden can assist business owners and professionals with the design and implementation of creative, tax effective strategies to improve the value of their business and personal net worth."},
  { imageUrl: "/img/benefits.svg",title: "employee benefits", summary: "Employee benefits have become a major component of employee compensation. In view of soaring health care costs and government cutbacks it is critical that employers receive value-added service from their consultants.",href: "/services/employee-benefits" },
  {
    imageUrl: "/img/succession.svg",
	title: "business succession",
    href: "/services/business-planning",
	summary: "Succession is an ongoing and ever changing process intended to minimize taxes and enable the orderly transition in ownership of a business or partnership."
  },
  { imageUrl: "/img/compensation.svg",title: "compensation strategies", summary: "For a successful business owner, competent, satisfied executives are vital. The business relies on these people to handle its operations. The owner needs to free up his or her time for strategic planning and creativity.", href: "/services/compensation" },
  { imageUrl: "/img/exec-benefits.svg",title: "executive benefits", summary:"Executive groups are the corporations' brain trust. They tend to put in more hours, are responsible for making the most difficult and important decisions and yet often have the lowest level of benefits in the organization relative to their compensation.", href: "/services/executive-benefits" },
  // { imageUrl: "/img/planning.svg",title: "financial planning", summary:"Financial planning is a conscious and deliberate process followed over an extended period of time during which wealth is accumulated, investments are made and protected and, eventually, income in retirement is derived and wealth is passed on to heirs.", href: "/services/financial-planning" },
];
export default {
  components: { Header, Container, ImageCardService },
  setup() {
    return { services };
  },
};
</script>
